import { useEffect, useReducer, useState } from 'react';
import Character from '../character/Character';

const characters: {
  name: string,
  img: string,
  index: number,
  thief?: boolean,
}[] = [
  {name: 'Man1', img: 'man1', index: 0},
  {name: 'Man2', img: 'man2', index: 0},
  {name: 'Man3', img: 'man3', index: 0},
  {name: 'Man4', img: 'man4', index: 0},
  {name: 'Man5', img: 'man5', index: 0},
  {name: 'Man6', img: 'man6', index: 0},
  {name: 'Woman1', img: 'woman1', index: 0},
  {name: 'Woman2', img: 'woman2', index: 0},
  {name: 'Woman3', img: 'woman3', index: 0},
  {name: 'Woman4', img: 'woman4', index: 0},
  {name: 'Woman5', img: 'woman5', index: 0},
  {name: 'Woman6', img: 'woman6', index: 0},
  {name: 'Woman7', img: 'woman7', index: 0},
  {name: 'Astronaut', img: 'astronaut', index: 0},
  {name: 'Pirate', img: 'pirate', index: 0},
  {name: 'Thief', img: 'thief', index: 0, thief: true},
];

const shuffledChars = () => {
  let shuffledCharacters = characters;
  for(var i=0; i<shuffledCharacters.length; i++) {
    shuffledCharacters[i].index += Math.floor(Math.random() * 1000);
  }
  let foundThief = false;
  return shuffledCharacters.sort((a, b) => a.index - b.index).filter((a, i) => {
    if (foundThief) {
      return i<10;
    }
    if (i<9) {
      if (a.thief) {
        foundThief = true;
      }
      return a.thief || i<9;
    }
    return a.thief;
  });
}

interface GameState {
  timeoutLength: number,
  gameover: boolean,
  thiefsFound: number,
  attempts: number,
}

let timer: any = undefined;
let reduceTimeoutValue = 200;

const initialState = {
  timeoutLength: 2000,
  gameover: false,
  thiefsFound: 0,
  attempts: 0,
}

const initialCharactersState: {
  characters: typeof characters;
  disabled: boolean;
  hideCharacters: boolean;
} = {
  characters: shuffledChars(),
  disabled: true,
  hideCharacters: true
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'reset':
      return {...state, hideCharacters: false, disabled: false, characters: shuffledChars()};
    case 'hide':
      return {...state, hideCharacters: true};
    case 'toggle':
      return {...state, hideCharacters: !state.hideCharacters};
    case 'disable':
      return {...state, disabled: true};
    case 'enable':
      return {...state, hideCharacters: false, disabled: false};
    case 'shuffle':
      return {...state, characters: shuffledChars()};
    default:
      return {...state, hideCharacters: false};
  }
}

export default function Characters(props: any) {
  const [state, setState] = useState<GameState>(initialState);
  const [charactersState, dispatch] = useReducer(reducer, initialCharactersState);

  function setTimer(timeoutLength: number) {
    timer = setTimeout(
      () => {
        dispatch({type: 'hide'});
        console.log('timer done!');
      },
      timeoutLength
    );
  }

  useEffect(() => {
    dispatch({type: 'enable'});
    dispatch({type: 'show'});
    setTimer(initialState.timeoutLength);
  }, []);

  function guessedThief(isThief: boolean): void {
    clearTimeout(timer);
    timer = undefined;
    dispatch({type: 'disable'});
    if (state.timeoutLength <= reduceTimeoutValue) {
      if (isThief) {
        setState({
          ...state,
          gameover: true,
          thiefsFound: state.thiefsFound + 1,
          attempts: state.attempts + 1,
        })
      } else {
        setState({
          ...state,
          gameover: true,
          attempts: state.attempts + 1,
        })        
      }
      return;
    }

    console.log(isThief);
    console.log(state.timeoutLength - reduceTimeoutValue);
    if (isThief) {
      setState({
        ...state,
        timeoutLength: state.timeoutLength - reduceTimeoutValue,
        thiefsFound: state.thiefsFound + 1,
        attempts: state.attempts + 1,
      });
    } else {
      setState({
        ...state,
        timeoutLength: state.timeoutLength - reduceTimeoutValue,
        attempts: state.attempts + 1,
      });
    }
    dispatch({type: 'reset'});
    setTimer(state.timeoutLength - reduceTimeoutValue);
  }

  if (state.gameover) return (
    <>
      <h1>Gameover!</h1>
      <h2>Thiefs found: {state.thiefsFound}</h2>
      <h2>Attempts: {state.attempts}</h2>
    </>
  )

  return (
    <>
      {
        (charactersState.characters as typeof characters).map((character, i) => 
          <button disabled={charactersState.disabled} type="button" className={'character' + (charactersState.hideCharacters ? ' hide' : '')} title={charactersState.hideCharacters ? '' : character.name} key={i} onClick={() => guessedThief(character.thief || false)}>
            <Character {...character} key={i}></Character>
          </button>
        )
      }
    </>
  )
}

import './Character.scss';

import { ReactComponent as Man1 } from '../assets/man1.svg';
import { ReactComponent as Man2 } from '../assets/man2.svg';
import { ReactComponent as Man3 } from '../assets/man3.svg';
import { ReactComponent as Man4 } from '../assets/man4.svg';
import { ReactComponent as Man5 } from '../assets/man5.svg';
import { ReactComponent as Man6 } from '../assets/man6.svg';
import { ReactComponent as Woman1 } from '../assets/woman1.svg';
import { ReactComponent as Woman2 } from '../assets/woman2.svg';
import { ReactComponent as Woman3 } from '../assets/woman3.svg';
import { ReactComponent as Woman4 } from '../assets/woman4.svg';
import { ReactComponent as Woman5 } from '../assets/woman5.svg';
import { ReactComponent as Woman6 } from '../assets/woman6.svg';
import { ReactComponent as Woman7 } from '../assets/woman7.svg';
import { ReactComponent as Astronaut } from '../assets/astronaut.svg';
import { ReactComponent as Pirate } from '../assets/pirate.svg';
import { ReactComponent as Thief } from '../assets/thief.svg';

interface characterProps {
    name: string;
    img: string;
    index: number;
    thief?: boolean;
};

function Character({name, img, index}: characterProps) {
    let characterImg;
    switch(img) {
        case 'man1':
            characterImg = <Man1></Man1>;
            break;
        case 'man2':
            characterImg = <Man2></Man2>;
            break;
        case 'man3':
            characterImg = <Man3></Man3>;
            break;
        case 'man4':
            characterImg = <Man4></Man4>;
            break;
        case 'man5':
            characterImg = <Man5></Man5>;
            break;
        case 'man6':
            characterImg = <Man6></Man6>;
            break;
        case 'woman1':
            characterImg = <Woman1></Woman1>;
            break;
        case 'woman2':
            characterImg = <Woman2></Woman2>;
            break;
        case 'woman3':
            characterImg = <Woman3></Woman3>;
            break;
        case 'woman4':
            characterImg = <Woman4></Woman4>;
            break;
        case 'woman5':
            characterImg = <Woman5></Woman5>;
            break;
        case 'woman6':
            characterImg = <Woman6></Woman6>;
            break;
        case 'woman7':
            characterImg = <Woman7></Woman7>;
            break;
        case 'astronaut':
            characterImg = <Astronaut></Astronaut>;
            break;
        case 'pirate':
            characterImg = <Pirate></Pirate>;
            break;
        case 'thief':
            characterImg = <Thief></Thief>;
            break;
        default:
            characterImg = <Man1></Man1>;
    }

    return (
        <div className="character-wrapper">
            {characterImg}
        </div>
    );
}
  
export default Character;

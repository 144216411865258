import './App.scss';
import Characters from './characters/Characters';

function App() {
  return (
    <div className="App">
      <section className="characters">
        <Characters></Characters>
      </section>
    </div>
  );
}

export default App;
